"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
(function () {
  /**
   * Default theme option for tooltip
   *
   * @type {[*]}
   */
  var TOOLTIP_DEFAULT_THEME = ['tooltipster-borderless', 'tooltipster-borderless-customized'];

  /**
   * Default tooltip maxwidth
   * @type {number}
   */
  var TOOLTIP_DEFAULT_MAXWIDTH = 500;

  /**
   * Default tooltip timer
   * @type {number}
   */
  var TOOLTIP_TIMER = 0;
  var two = 2;
  var defaultSpecificDelay = 500;
  var DEFAULT_TOOLTIPSTER_PROPERTIES = {
    theme: TOOLTIP_DEFAULT_THEME,
    maxWidth: TOOLTIP_DEFAULT_MAXWIDTH,
    debug: false,
    contentAsHTML: true,
    updateAnimation: null,
    timer: TOOLTIP_TIMER
  };
  var SMALL_TOOLTIPSTER_PROPERTIES = Object.assign({}, DEFAULT_TOOLTIPSTER_PROPERTIES, {
    maxWidth: TOOLTIP_DEFAULT_MAXWIDTH / two
  });
  var DEFAULT_DELAY = 500;
  var DEFAULT_DELAY_TO_OPEN = 300;
  function enableTooltipForElement(elem, properties) {
    var instanceProperties = _objectSpread(_objectSpread({}, DEFAULT_TOOLTIPSTER_PROPERTIES), properties);
    setTimeout(function () {
      $(elem).tooltipster(Object.assign({
        delay: [DEFAULT_DELAY_TO_OPEN, DEFAULT_DELAY_TO_OPEN]
      }, instanceProperties));
    }, DEFAULT_DELAY);
  }

  // eslint-disable-next-line max-params
  function enableTooltip() {
    var class_default = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.tooltip-default:not([title=""])';
    var class_small = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.tooltip-small:not([title=""])';
    var delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_DELAY;
    var timer = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    var delayToOpen = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : DEFAULT_DELAY_TO_OPEN;
    if (timer) TOOLTIP_TIMER = timer;
    function bindHover(elementSelector, properties) {
      $(elementSelector).off('mousemove.tooltipster')
      // handler is executed once just to start tooltipster
      .one('mousemove.tooltipster', function () {
        var tooltipsteredClass = 'tooltipstered';
        if (!$(this).hasClass(tooltipsteredClass)) {
          var title = $(this).data('tooltipster-title');
          $(this).tooltipster(Object.assign({
            delay: [delayToOpen, DEFAULT_DELAY_TO_OPEN]
          }, properties));
          if (title) {
            $(this).tooltipster('content', title);
          }
          if ($("".concat(elementSelector, ":hover")).length > 0) {
            $(this).tooltipster('open');
          }
        }
      });
    }
    function lazyLoadTooltip(elementSelector, properties) {
      if (!delay) {
        bindHover(elementSelector, properties);
        return;
      }
      setTimeout(function () {
        bindHover(elementSelector, properties);
      }, delay);
    }
    if (class_default) lazyLoadTooltip(class_default, DEFAULT_TOOLTIPSTER_PROPERTIES);
    if (class_small) lazyLoadTooltip(class_small, SMALL_TOOLTIPSTER_PROPERTIES);
  }
  function enableDefaultTooltip(elements) {
    $(elements).tooltipster(Object.assign({
      delay: [DEFAULT_DELAY_TO_OPEN, DEFAULT_DELAY_TO_OPEN]
    }, DEFAULT_TOOLTIPSTER_PROPERTIES));
  }
  function enableModalToolTip(modalElement) {
    enableDefaultTooltip(modalElement.querySelectorAll('[title]'));
  }

  /**
   *  This function create a tooltip with specific configuration.
   *
   *  @param $element -> is the jquery element;
   *  @param maxWidth -> is the max width that the tooltip can assume;
   *  @param message -> is the message that will apear in the tooltip;
   *  @param position -> is the position where the tootip will be positined
   *                      The positions can be: 'top', 'bottom', 'right', 'left'
   *                      For more information access: http://iamceege.github.io/tooltipster/#positioning
   *  @param delay -> Delay to start the tooltip in ms
   */
  // eslint-disable-next-line max-params
  function setSpecificTooltip($element, maxWidth, message, position) {
    var delay = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : defaultSpecificDelay;
    var tooltip_options = {
      theme: TOOLTIP_DEFAULT_THEME,
      maxWidth: maxWidth || TOOLTIP_DEFAULT_MAXWIDTH,
      debug: false,
      contentAsHTML: true,
      content: message,
      side: position ? position : null
    };
    if (typeof message !== 'undefined') {
      tooltip_options.content = message;
    }
    setTimeout(function () {
      $element.tooltipster(tooltip_options);
    }, delay);
  }
  function updateTooltipContent($jqueryElement, content) {
    if (!$jqueryElement || !$jqueryElement.length) {
      return;
    }
    var tooltipsteredClass = 'tooltipstered';
    var isTooltipstered = $jqueryElement.hasClass(tooltipsteredClass);
    if (isTooltipstered) {
      $jqueryElement.tooltipster('content', content);
      return;
    }
    // with lazy load approach if the element's tooltip
    // is not instantiated yet, it must set the title attribute
    $jqueryElement.attr('title', content);
  }
  function isTooltipEnable(elem) {
    //Need to have Initial Title and Data not be empty
    return Boolean($(elem).data('tooltipsterInitialTitle') && !$.isEmptyObject($(elem).data()));
  }
  window.moduleExport({
    enableTooltip: enableTooltip,
    enableTooltipForElement: enableTooltipForElement,
    TOOLTIP_DEFAULT_MAXWIDTH: TOOLTIP_DEFAULT_MAXWIDTH,
    enableDefaultTooltip: enableDefaultTooltip,
    enableModalToolTip: enableModalToolTip,
    setSpecificTooltip: setSpecificTooltip,
    updateTooltipContent: updateTooltipContent,
    isTooltipEnable: isTooltipEnable
  }, typeof module !== 'undefined' && module);
})();